import loadable from '@loadable/component';
import { useLocation } from '@reach/router';
import { graphql } from 'gatsby';
import { parse } from 'query-string';
import * as React from 'react';
import { FaFilter } from 'react-icons/fa';
import Select from 'react-select';

import Container from 'components/Container/Container';
import ContentSidebar from 'components/ContentSidebar/ContentSidebar';
import Filters from 'components/Filters/Filters';
import FiltersButton from 'components/FiltersButton/FiltersButton';
import FiltersSelection from 'components/FiltersSelection/FiltersSelection';
import HeroText from 'components/HeroText/HeroText';
import Layout from 'components/Layout';
import ProductContainer from 'components/Product/ProductContainer';
import ProductTile from 'components/Product/ProductTile';
import Seo from 'components/Seo';

import { FiltersProvider } from 'utilities/FiltersContext';
import {
  hasChangedPrice,
  hasSelectedFilters,
  initialFilters,
  handleProducts,
  getPageCount,
  getOffset,
  handleFilters,
} from 'utilities/category';
import { transformParamToFilter } from 'utilities/filters';
import { isBrowser } from 'utilities/utils';

import {
  breaker,
  container,
  li,
  linkClass,
  active,
} from 'styles/components/paginate.module.scss';
import * as styles from 'styles/pages/category.module.scss';

import 'styles/overrides/react-select.scss';

// temp fix for react-paginate
if (typeof window !== 'undefined') {
  window.global = window;
}
const ReactPaginate =
  typeof window !== 'undefined'
    ? loadable(() => import('react-paginate'))
    : null;

const options = [
  { value: 'default', label: 'Χωρίς ταξινόμηση' },
  { value: 'asc', label: 'Αύξουσα τιμή' },
  { value: 'desc', label: 'Φθίνουσα τιμή' },
];

export default function CategoryPage({
  data: { strapiCategory: category },
  pageContext: { products: allProducts, filteredProducts, filters },
}) {
  const location = useLocation();
  const { size, brand, color } = parse(location.search);
  const { min, max } = filters;
  const numberPerPage = 12;
  const [selectedFilters, setSelectedFilters] = React.useState(() => {
    return {
      size: transformParamToFilter(size),
      brand: transformParamToFilter(brand),
      color: transformParamToFilter(color),
    };
  });
  const [sorting, setSorting] = React.useState(options[0]);
  const [price, setPrice] = React.useState([min, max]);
  const [offsetUser, setOffsetUser] = React.useState(0);

  const data = handleProducts(
    filteredProducts,
    allProducts,
    selectedFilters,
    price,
    min,
    max,
    sorting.value
  );

  const pageCount = getPageCount(data.length, numberPerPage);
  const offset = getOffset(data.length, offsetUser, numberPerPage, pageCount);
  const currentData = data.slice(offset, offset + numberPerPage);
  const currentPage = offset / numberPerPage;

  const resetPrice = () => setPrice([min, max]);
  const handleFilter = (filter, type) => {
    setSelectedFilters(handleFilters(selectedFilters, filter, type));
  };
  const handleReset = () => {
    setSelectedFilters(initialFilters);
    resetPrice();
  };
  const handleSelect = (selectedOption) => {
    setSorting(selectedOption);
  };
  const handleSlider = (change) => {
    setPrice([...change]);
  };
  const handlePageClick = (event) => {
    const selected = event.selected;
    if (isBrowser) {
      setTimeout(() => {
        document.getElementById('products-section').scrollIntoView({
          behavior: 'smooth',
        });
      }, 10);
    }
    setOffsetUser(Math.ceil(selected * numberPerPage));
  };

  return (
    <Layout fullWidth={true}>
      <Seo
        title={category.title}
        description={`Δείτε ${category.title} σε μοναδικές τιμές. Γυναικεία ρούχα, εξαιρετικές χειροποίητες δημιουργίες, αξεσουάρ και βαπτιστικά πακέτα.`}
        meta={
          currentData.length > 0 && [
            ...currentData[0].images.map((image) => {
              return {
                property: 'og:image',
                content: `${process.env.GATSBY_API_URL}${image.url}`,
              };
            }),
            ...currentData[0].images.map((image) => {
              return {
                property: 'twitter:image',
                content: `${process.env.GATSBY_API_URL}${image.url}`,
              };
            }),
          ]
        }
      />
      <HeroText title={'Our products'} subtitle={category.title} />
      <div className={styles.container} id="products-section">
        <Container isContent withPadding>
          <FiltersProvider>
            <FiltersSelection
              handleReset={handleReset}
              hasSelectedFilters={hasSelectedFilters(selectedFilters)}
              hasChangedPrice={hasChangedPrice(price, min, max)}
              resetPrice={resetPrice}
              selectedFilters={selectedFilters}
              price={price}
              handleFilter={handleFilter}
            />
            <ContentSidebar
              sidebar={
                (data.length > 0 ||
                  hasSelectedFilters(selectedFilters) ||
                  hasChangedPrice(price, min, max)) && (
                  <Filters
                    handleFilter={handleFilter}
                    handleSlider={handleSlider}
                    filters={filters}
                    filteredProducts={filteredProducts}
                    selectedFilters={selectedFilters}
                    price={price}
                  />
                )
              }
              withHandle={false}
              withOverlay
              icon={<FaFilter />}
              content={
                <>
                  {data.length > 0 && (
                    <div className={styles.subheaderContainer}>
                      <p className={styles.productsCountText}>
                        {data.length === 1
                          ? `1 προϊόν`
                          : `${data.length} προϊόντα`}
                      </p>
                      <FiltersButton />
                      <Select
                        className={styles.control}
                        classNamePrefix="react-select"
                        value={sorting}
                        label="Ταξινόμηση"
                        placeholder="Ταξινόμηση"
                        onChange={handleSelect}
                        options={options}
                        isSearchable={false}
                      />
                    </div>
                  )}
                  <ProductContainer>
                    {currentData.length > 0 ? (
                      currentData.map((product) => (
                        <ProductTile key={product._id} product={product} />
                      ))
                    ) : (
                      <p style={{ margin: '2rem 0' }}>
                        Δεν υπάρχουν διαθέσιμα προϊόντα…
                      </p>
                    )}
                  </ProductContainer>
                  {ReactPaginate && (
                    <ReactPaginate
                      previousLabel={false}
                      nextLabel={false}
                      breakLabel={'...'}
                      breakClassName={breaker}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageClick}
                      containerClassName={container}
                      pageClassName={li}
                      pageLinkClassName={linkClass}
                      activeClassName={active}
                      forcePage={currentPage}
                    />
                  )}
                </>
              }
            />
          </FiltersProvider>
          <br />
        </Container>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query SingleCategory($id: String!) {
    strapiCategory(strapiId: { eq: $id }) {
      title
      slug
      strapiParent {
        slug
        title
      }
      localFile {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  }
`;
